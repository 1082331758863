class ImagenScrollOrig{
    constructor(target, scope){
        this.velocidad = 1/((target.getAttribute('time')|| 2)*1000)
        this.target = target;
        this.progress = {value: 0}
        const textAnimate = target.querySelectorAll('.text-animate')
        let items = Array.from(target.querySelectorAll('.imagen-scroll-item')).reverse()

        this.scroller = Scrollbar.get(window.$pageWrapper[0].querySelector('.js-smooth-scroll'))
        this.tl = gsap.timeline()
        this.tl.progress(0).pause()
        items.forEach(imagen => this.tl.to(imagen, {opacity:0, duration:1}))
        textAnimate.forEach((text, i) => this.tl.from(text, {opacity:0, x:-150 , duration:1}, i))
        

        this.observador = Observer.create({
            target: target,         
            type: "wheel,touch", 
            preventDefault: true,
            onChange: this.incrementar.bind(this),
        })
        this.observador.disable()

        ScrollTrigger.create({
            trigger: target,
            start: "top top",
            end: "bottom bottom",
            onEnter: this.activeObserve.bind(this),
            onEnterBack: this.activeObserve.bind(this),
        })
    }
    
    activeObserve(){
        this.observador.enable()
        this.scroller.scrollIntoView(this.target, {alignToTop: false})
    }

    incrementar({deltaY, event}){
        const incremento = event.type === 'wheel' ? deltaY * this.velocidad : deltaY * .05
        gsap.to(this.progress,{
            duration: .3,
            value: ()=> gsap.utils.clamp(0,1, this.progress.value + incremento),
            onUpdate: ()=> {
                this.tl.progress(this.progress.value)
                if (this.progress.value == 0 || this.progress.value == 1) {
                    const y = this.scroller.scrollTop
                    if (this.progress.value == 0 ) {
                        this.scroller.scrollTo(0, y - 100, 100)
                    }else{
                        this.scroller.scrollTo(0, y + 100, 100)
                    }
                    this.observador.disable()
                }
            }
        })
    }
    
    snap(valor, incremento){
        return Math.floor(valor / incremento) * incremento; 
    }
}
class ImagenScroll{
    constructor(target, scope){
        this.target = target;
        this.tl = gsap.timeline()
        const textAnimate = target.querySelectorAll('.text-animate')
        let items = Array.from(target.querySelectorAll('.imagen-scroll-item')).reverse()
        
        items.forEach(imagen => this.tl.to(imagen, {opacity:0, duration:1}))
        textAnimate.forEach((text, i) => this.tl.from(text, {opacity:0, x:-150 , duration:1}, i))
        
        const proxyElement = scope[0].querySelector('.js-smooth-scroll')
        const bodyScrollBar = Scrollbar.get(proxyElement);


        if (bodyScrollBar) {
            ScrollTrigger.scrollerProxy(proxyElement, {
            scrollTop(value) {
                if (arguments.length) {
                bodyScrollBar.scrollTop = value; // setter
                }
                return bodyScrollBar.scrollTop;    // getter
            },
            getBoundingClientRect() {
                return {top: 0, left: 0, width: window.innerWidth, height: window.innerHeight};
            }
            });
            bodyScrollBar.addListener(ScrollTrigger.update);
            ScrollTrigger.create({
                animation: this.tl,
                trigger: target,
                scroller: proxyElement,
                pin: true,
                pinType: "transform",
                scrub:true,
                start: "top top",
                end: "+=2000",
            })
        }else{
            ScrollTrigger.create({
                animation: this.tl,
                trigger: target,
                pin: true,
                scrub:true,
                start: "top top",
                end: "+=2000",
            })
        }
        

        // // Tell ScrollTrigger to use these proxy getter/setter methods for the "body" element: 
        
    }
    
} 