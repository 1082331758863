const
    keyFramesClose = [
        { opacity: 1, transform: "scale(1)" },
        { opacity: 0, transform: "scale(0)" },
    ],
    keyFramesOpen = [
        { opacity: 0, transform: "scale(0)" },
        { opacity: 1, transform: "scale(1)" },
    ],
    options = {
        duration: 200,
        fill: "forwards"
    };

//Clase para el modal generico del cual envuelve a todos
class Modal extends HTMLElement {
    constructor() {
        super()
    }

    connectedCallback() {
        const id = this.getAttribute('id');
        const botones = document.querySelectorAll(`[modal="${id}"]`)
        const botonesCerrar = this.querySelectorAll('[close-modal]')
        botones.forEach(boton => boton.addEventListener('click', this.openModal.bind(this)))
        botonesCerrar.forEach(boton => boton.addEventListener('click', this.cerrarModal.bind(this)))
    }
    
    openModal(e) {
        this.setAttribute('open', "")
        this.animate(keyFramesOpen, options)
    }
    
    cerrarModal(e) {
        this.setAttribute('open', "")
        this.animate(keyFramesClose, options).finished.then(e => this.removeAttribute('open'))
    }
}

class ContenidoVinos extends HTMLElement {
    constructor() {
        super()
    }

    connectedCallback() {
        this.scrollbar = Scrollbar.init(this, { damping: 0.12 });
    }
}

class ContenidoVinedo extends HTMLElement {
    constructor() {
        super()
    }

    connectedCallback() {
        const botonesAbrir = document.querySelectorAll('button[slide], a[slide]')
        botonesAbrir.forEach(boton => boton.addEventListener('click', this.initSwiper.bind(this)))
        this.customPaginador = this.querySelectorAll('li')
        this.customPaginador.forEach((item, i) => {
            item.addEventListener('click', () =>  this.swiper.slideTo(i+1))
        })
        this.clases = Array.from(this.querySelectorAll('.swiper-slide'))
        this.clases = this.clases.map(slider => slider.dataset.class || '')
    }

    initSwiper({currentTarget}) {
        if (!this.swiper) {
            this.swiper = new Swiper(this.querySelector('.swiper'),{
                loop: true,
                pagination: {
                  el: this.querySelector('.swiper-pagination'),
                  clickable: true,
                },
            });
            this.swiper.on('slideChange', this.setActiveClass.bind(this))
        }
        const realIndex = Number(currentTarget.getAttribute('slide'))
        
        this.swiper.update()
        this.swiper.slideToLoop(realIndex, 0, false)
        this.setActiveClass({realIndex})
    }

    setActiveClass({realIndex}) {
        this.className = this.clases[realIndex]
        this.customPaginador.forEach((item, index) => {
            if ((index) === realIndex) {
                item.classList.add('active');
            }else{
                item.classList.remove('active');
            }
        })
    }
}


customElements.define('modal-wrapper', Modal);
customElements.define('contenido-vinos', ContenidoVinos);
customElements.define('contenido-vinedo', ContenidoVinedo);