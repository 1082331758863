


class Accordion {
    constructor() {
        this.links = Array.from(window.$pageWrapper[0].querySelectorAll('.accordion .accordion-a'))        
        setTimeout(this._AccordionJquery.bind(this), 500);
    }
    _AccordionJquery() {
        const posiciones = this.links.map(link => link.offsetParent.offsetTop + link.offsetParent.offsetParent.offsetTop)
        const header = document.getElementById('page-header')

        $('.accordion .accordion-a.first').addClass('active').next().slideDown();
        this.links.forEach((link,i) => {
            link.addEventListener('click',function (j) {
                var dropDown = $(this).closest('.accordion-li').find('.accordion-content');
                $(this).closest('.accordion').find('.accordion-content').not(dropDown).slideUp();
                if ($(this).hasClass('active')) {
                    $(this).removeClass('active');
                } else {
                    $(this).closest('.accordion').find('.accordion-a.active').removeClass('active');
                    $(this).addClass('active');
                }
                //Scroll.scrollTo({y:posiciones[i]-(header.classList.contains('ocultar') ? 60:100)});
                dropDown.stop(false, true).slideToggle()
                j.preventDefault();
            })    
        });
    
    }
}











