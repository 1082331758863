class MenuFijo extends HTMLElement{
    constructor(){
        super()
    }
    
    connectedCallback() {
        this.class = this.className
        this.secciones = document.querySelectorAll('[data-class-menu]')
        this.dot = this.querySelector('span')
        this.links = Array.from(this.querySelectorAll('a'))
        this.anclas = this.links.map(link => document.querySelector(link.hash))

        this.initEventListener()

        window.addEventListener('arts/barba/transition/end', this.initEventListener.bind(this))
        
    }

    initEventListener(){
        this.secciones.forEach((seccion, i) => {
            ScrollTrigger.create({
                trigger: seccion,
                start: "top center",
                onEnter: ()=> this.setClass(seccion),
                onLeaveBack: ()=> this.setClass(this.secciones[i-1]),
            })
        });

        this.anclas.forEach((ancla, i) => {
            ScrollTrigger.create({
                trigger: ancla,
                start: "top center",
                onEnter: ()=> this.setDot(i),
                onLeaveBack: ()=> this.setDot(i-1),
            })
        });
    }

    setClass(seccion){
        this.className = this.class + ' ' + seccion.dataset.classMenu
    }
    setDot(i){
        this.links.forEach(link => link.classList.remove('activo'))
        this.links[i].classList.add('activo')
        this.dot.animate(
            [{transform: `translateY(${this.links[i].offsetTop}px)`}],
            {duration: 100, fill:"forwards"}
        )
    }
}

customElements.define('menu-fijo', MenuFijo)