class Tabs extends HTMLFormElement{
    constructor(){
        super()
    }
    
    connectedCallback(){
        this.contenidos = []
        this.addEventListener("change", this.toggle)
        const inputs = this.querySelectorAll("input")
        inputs.forEach(element => {
            this.contenidos.push(document.getElementById(element.value))
            element.name="tab"
        });
    }
    
    toggle({srcElement}){
        const id = srcElement.value
        this.contenidos.forEach((element, i) => {
            if (element.id === id) {
                element.classList.remove('d-none')
            }else{
                element.classList.add('d-none')
            }
        })
    }

}

customElements.define('abu-tabs', Tabs, { extends: "form" })