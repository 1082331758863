class Galeria3d extends HTMLElement {
    constructor() {
        super()
        this.widthMovil = 769
        this.imagenes = this.querySelectorAll("galeria-imagenes img")
        this.wrapper = this.querySelectorAll("galeria-imagenes")
        this.anclas = this.querySelectorAll("galeria-panelItem")
        this.paginacion = this.querySelectorAll("galeria-paginacion span")
        this.contenidos = this.querySelectorAll("galeria-contenido")
        this.nav = this.querySelectorAll("galeria-nav button")
        this.progreso = { valor: 0 }
        this.step = 1 / this.imagenes.length
    }

    connectedCallback() {
        this.nav.forEach(button => button.addEventListener('click', this.navegar.bind(this)))
        this.anclas.forEach((ancla, i) => {
            const theta = i / this.anclas.length
            ancla.onclick = ()=> {
                gsap.killTweensOf(this.progreso)
                const valor = (i ? theta: (this.progreso.value >.5) ? 1:0) + Math.round(this.progreso.valor)
                this.rotar(valor)
            }
        })
        if (window.innerWidth > this.widthMovil){
            Observer.create({
                target: this.wrapper,
                type: "pointer,touch",
                preventDefault: true,
                onChange: (self) => {
                    gsap.killTweensOf(this.progreso)
                    const p = self.event.type === 'wheel' ? self.deltaY * -.0002 : self.deltaX * -.02
                    this.rotar((this.progreso.valor + p) )
                },
                onDragEnd: ()=> this.autoPlay()
            })
        }

        this.rotar()
        this.autoPlay(0)
    }

    navegar(e){
        const dir = Number(e.currentTarget.dataset.dir)
        const valor = this.snap(this.progreso.valor + this.step * dir, this.step)
        this.rotar(valor)
    }

    rotar(valor=0) {
        clearTimeout(this.timeOut)
        gsap.killTweensOf(this.progreso)
        gsap.to(this.progreso, {
            duration: 2,
            ease: 'power4.out',
            valor,
            onUpdate: this.render.bind(this),
        })
    }

    autoPlay(time = 10){
        if (window.innerWidth > this.widthMovil){
            this.timeOut = setTimeout(()=>{
                gsap.killTweensOf(this.progreso)
                gsap.to(this.progreso, {
                    duration: 26,
                    ease: 'none',
                    valor: "+=1",
                    repeat:-1,
                    onUpdate: this.render.bind(this),
                })
            }, time * 1000)
        }
    }

    render(){
        const actual = Math.abs(Math.round(this.progreso.valor % 1 * this.imagenes.length))%this.imagenes.length
        this.paginacion.forEach((dot, i) => dot.className = i == actual ? 'active' :'')
        this.anclas.forEach((ancla, i) => ancla.className = i == actual ? 'active' :'')
        this.contenidos.forEach((contenido, i) => {
            if (i == actual) {
                contenido.setAttribute('open', true)
            }else{
                contenido.removeAttribute('open', true)
            }
        })
        this.imagenes.forEach((imagen, i) => {
            const theta = i / this.imagenes.length - this.progreso.valor
            const x = (Math.sin(theta * Math.PI * 2) * imagen.width*.75).toFixed(0)
            const z = (Math.cos(theta * Math.PI * 2) * imagen.width*.75).toFixed(0)
            gsap.set(imagen, {x, z, zIndex:z})
        })
    }

    snap(valor, incremento){
        return Math.round(valor / incremento) * incremento; 
    }
}

customElements.define('galeria-3d', Galeria3d)
