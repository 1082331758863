class HeaderControl{
    constructor(){
        const header = document.getElementById('page-header')
        Observer.create({
            target: window,         // can be any element (selector text is fine)
            type: "wheel,touch",    // comma-delimited list of what to listen for ("wheel,touch,scroll,pointer")
            onUp: ({event}) => {
                if (event.type === "wheel") {
                    header.classList.remove('ocultar')
                }else{
                    header.classList.add('ocultar')
                }
            }, 
            onDown: ({event}) => {
                if (event.type === "touch") {
                    header.classList.remove('ocultar')
                }else{
                    header.classList.add('ocultar')
                }
            },
          });
    }
}